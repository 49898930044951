import React from "react"
import { schoolSettingsQuery } from "domains/accountSettings/graphql"
import { requestQuery } from "domains/requests/graphql"
import { studentsQuery } from "domains/students/graphql"
import { dashboardQuery } from "domains/dashboard/graphql"

import { SuccessModal, CautionModal } from "components"
import {
  Administrators,
  GradeSettings,
  MiscFileUpload,
  DownloadStudentHistory,
  Modes,
  Preferences,
  SurveySettings,
  AdvancedFeatures,
} from "domains/accountSettings/components"

import { handleSessionExpired } from "util/app"
import { ADMIN_ONLY } from "constants/resolverTypes"

const initialAdminData = schoolSettings => {
  return {
    firstName: "",
    lastName: "",
    positionTitle: "",
    email: "",
    schools: [],
    accounts: [],
    mfa: schoolSettings.requireMfa,
  }
}

export class AccountSettings extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      cautionModal: {
        isOpen: false,
        heading: null,
        text: null,
        buttonText: null,
        onClick: null,
      },
      adminData: initialAdminData(props.schoolSettings),
      successModal: "",
      successModalHeading: "",
      errors: {},
    }
  }

  toggleCautionModal = object => {
    this.setState({
      cautionModal: object || { isOpen: false },
    })
  }

  toggleSuccessModal = () => {
    this.setState({
      successModal: "",
      successModalHeading: "",
    })
  }

  onChange = (e, value) => {
    const { updateSchoolSettingMutation, schoolId } = this.props
    // toggle value
    const schoolSettingParams = {
      schoolId,
      [e.target.name]: !value,
    }

    this.setState({
      loading: true,
    })

    updateSchoolSettingMutation({
      variables: { schoolSettingParams },
      refetchQueries: [
        {
          query: schoolSettingsQuery,
          variables: { schoolId },
        },
      ],
    })
      .then(() => {
        // set time out to allow longer transitioning of cursor
        setTimeout(() => {
          this.setState({
            loading: false,
          })
        }, 500)
      })
      .catch(error => {
        handleSessionExpired(error)
        this.setState({
          loading: false,
        })
      })
  }

  updateAdminOnly = () => {
    const { updateSettings, settings, schoolId } = this.props
    const adminOnly = !settings.adminOnlyRequests
    const refetchQueries = [
      {
        query: studentsQuery,
        variables: { adminOnly, schoolId },
      },
      {
        query: requestQuery,
        variables: { adminOnly, schoolId },
      },
      {
        query: dashboardQuery,
        variables: { adminOnly, schoolId },
      },
    ]

    updateSettings({
      variables: { type: ADMIN_ONLY, value: adminOnly },
      refetchQueries,
    })
  }

  render() {
    const {
      client,
      loadingSchool,
      loadingAdmins,
      loadingInvitations,
      loadingPrefences,
      loadingSchoolGrades,
      settings,
      school = {},
      schoolSettings = {},
      myprofile = {},
      currentSchoolGrades = [],
      userRefetchQueries,
      createOrUpdateSisConfigPowerschoolMutation,
      invitations,
      revokeSchoolInvitationMutation,
      removeUserFromSchoolsMutation,
    } = this.props

    const {
      loading,
      cautionModal,
      successModal,
      successModalHeading,
      adminData,
      errors,
    } = this.state

    const {
      teachersToAddTeacherRequests,
      teachersToAddStudentRequests,
      feederSchool,
      avoidSameTeacher,
      includeStudentsWithNoValidFriends,
      showTeachersInSolver,
    } = schoolSettings

    // Sort users by id
    let users = []
    if (this.props.users) {
      users = Array.from(this.props.users).sort((a, b) => {
        return a.id - b.id
      })
    }

    return (
      <div
        className={`container u-mw-750 o-component ${
          loading ? "cursor-wait" : ""
        }`}>
        <h1 className="mb-5 pb-4">Settings</h1>
        <Administrators
          apolloClient={client}
          loading={loadingAdmins || loadingSchool || loadingInvitations}
          adminData={adminData}
          errors={errors}
          users={users}
          myprofile={myprofile}
          userRefetchQueries={userRefetchQueries}
          currentSchool={school}
          schoolSettings={schoolSettings}
          invitations={invitations}
          revokeSchoolInvitationMutation={revokeSchoolInvitationMutation}
          removeUserFromSchoolsMutation={removeUserFromSchoolsMutation}
        />
        <SurveySettings
          teachersToAddTeacherRequests={teachersToAddTeacherRequests}
          teachersToAddStudentRequests={teachersToAddStudentRequests}
          feederSchool={feederSchool}
          onChange={this.onChange}
        />

        <Preferences
          loading={loadingPrefences}
          onChange={this.onChange}
          avoidSameTeacher={avoidSameTeacher}
          includeStudentsWithNoValidFriends={includeStudentsWithNoValidFriends}
        />
        <Modes
          adminOnlyRequests={settings.adminOnlyRequests}
          updateAdminOnly={this.updateAdminOnly}
          showTeachersInSolver={showTeachersInSolver}
          updateShowTeachersInSolver={e =>
            this.onChange(e, showTeachersInSolver)
          }
        />
        <GradeSettings
          loading={loading}
          loadingSchoolGrades={loadingSchoolGrades}
          currentSchoolGrades={currentSchoolGrades}
        />
        <MiscFileUpload school={school} />
        <DownloadStudentHistory />
        <AdvancedFeatures
          school={school}
          createOrUpdateSisConfigPowerschoolMutation={
            createOrUpdateSisConfigPowerschoolMutation
          }
        />
        <CautionModal
          isOpen={cautionModal.isOpen}
          toggle={this.toggleCautionModal}
          heading={cautionModal.heading}
          text={cautionModal.text}
          buttonText={cautionModal.buttonText}
          onButtonClick={cautionModal.onClick}
          error={errors.mutation}
        />
        <SuccessModal
          isOpen={successModal !== ""}
          toggle={this.toggleSuccessModal}
          heading={successModalHeading}
          text={successModal}
        />
      </div>
    )
  }
}
