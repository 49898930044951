import React from "react"
import { NavLink } from "react-router-dom"
import { Nav, Row, NavbarBrand, NavItem } from "reactstrap"
import { withApollo, graphql } from "@apollo/client/react/hoc"
import compose from "lodash.flowright"
import { userProfileQuery } from "domains/auth/graphql"
import {
  settingsClientQuery,
  schoolQuery,
} from "domains/accountSettings/graphql"
import { dashboardQuery } from "domains/dashboard/graphql"

import { SchoolListModal } from "components"
import { UserDropdown } from "domains/app/components"

import Logo from "images/logo.png"

import { canAccessSolver, getSchoolId } from "util/app"
import { getPropertyIfDefined } from "util/objUtil"

import { SCHOOL_ID } from "constants/storageTokens"
import { TEACHER } from "domains/app/constants/roleTypes"
import { isPrivilegedRole } from "util/userUtil"
import { AccountTypeContext } from "config/accountTypeContext"

const styles = {
  logo: { maxWidth: 100 },
  navContainer: {
    backgroundColor: "#FFF",
    position: "sticky",
    top: 0,
    zIndex: 10,
  },
  adminLogo: { maxWidth: 32 },
}

class NavComponent extends React.Component {
  static contextType = AccountTypeContext

  constructor(props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      schoolListModal: getSchoolId() === null,
    }
  }

  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    })
  }

  onLogOut = () => {
    window.location.href = "/logout"
  }

  toggleSchoolListModal = () => {
    this.setState({
      schoolListModal: !this.state.schoolListModal,
    })
  }

  onSchoolClick = id => {
    if (id) {
      sessionStorage.setItem(SCHOOL_ID, id)
    } else {
      sessionStorage.removeItem(SCHOOL_ID)
    }
    // Force refresh to load sessionStorage changes
    window.location.reload()
  }

  render() {
    const { user, settings, school, schoolError, dashboard = {} } = this.props

    const { dropdownOpen, schoolListModal } = this.state

    const { newClassCount = 0, studentCount = 0 } = dashboard

    const hasStudents = studentCount > 0
    const hasNewClasses = newClassCount > 0

    const isTeacher = user && user.role === TEACHER
    const isSuper = user && isPrivilegedRole(user.role)

    const adminMode = settings.adminOnlyRequests

    const newAccountLock = dashboard.newAccountLock

    const gettextObj = this.context.gettextObj

    const userHasAccessToSchool =
      user &&
      user.schools.some(school => {
        return school.id === getSchoolId()
      })

    if (schoolError && (isSuper || !userHasAccessToSchool)) {
      // Unset the school and reload
      this.onSchoolClick()
      return
    }

    return (
      <Row
        style={styles.navContainer}
        className="c-nav d-flex align-items-center justify-content-between py-2 d-print-none">
        {(isSuper || user.schools.length > 1) && schoolListModal && (
          <SchoolListModal
            isOpen={schoolListModal}
            toggle={this.toggleSchoolListModal}
            onSchoolClick={this.onSchoolClick}
            isSuper={isSuper}
          />
        )}

        <div className="col-12 d-flex justify-content-between">
          <NavbarBrand href="/" className="pl-4">
            <img style={styles.logo} src={Logo} alt="Class Solver Logo" />
          </NavbarBrand>
          {!isTeacher && (
            <Nav className="nav-left align-items-center d-inline-flex d-print-none mr-auto pl-4 color-grey-dark">
              <NavItem>
                <NavLink end to="/">
                  Dashboard
                </NavLink>
              </NavItem>
              <span>|</span>
              <NavItem>
                <NavLink
                  className={school ? "" : "btn disabled"}
                  end
                  to="/Students">
                  Students
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  end
                  to="/Teachers"
                  className={school && hasStudents ? "" : "btn disabled"}>
                  Teachers
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  end
                  to="/Requests"
                  className={
                    school && hasStudents && newAccountLock !== true
                      ? ""
                      : "btn disabled"
                  }>
                  Requests
                </NavLink>
              </NavItem>
              <span>|</span>
              <NavItem>
                <NavLink
                  end
                  to="/Classes"
                  className={
                    school && hasStudents && newAccountLock !== true
                      ? ""
                      : "btn disabled"
                  }>
                  {gettextObj.gettext("Classes")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  end
                  to="/Solver"
                  className={
                    !school ||
                    !hasStudents ||
                    !hasNewClasses ||
                    !canAccessSolver(
                      getPropertyIfDefined(school, "paymentStatus.status")
                    ) ||
                    newAccountLock
                      ? "btn disabled"
                      : ""
                  }>
                  Solver
                </NavLink>
              </NavItem>
            </Nav>
          )}
          {!isTeacher && (
            <UserDropdown
              dropdownOpen={dropdownOpen}
              toggle={this.toggle}
              toggleSchoolListModal={this.toggleSchoolListModal}
              school={school}
              onLogOut={this.onLogOut}
              myprofile={user}
              adminMode={adminMode}
            />
          )}
        </div>
      </Row>
    )
  }
}

const selectedSchool = user => {
  const schoolId = getSchoolId()

  if (!schoolId && user.schools.length === 1) {
    sessionStorage.setItem(SCHOOL_ID, user.schools[0].id)
    return user.schools[0].id
  }

  return schoolId
}

export const NavComp = compose(
  graphql(userProfileQuery, {
    props: ({ data: { myprofile: user } }) => ({
      user,
    }),
  }),
  graphql(settingsClientQuery, {
    props: ({ data: { settings } }) => ({
      settings,
    }),
  }),
  graphql(dashboardQuery, {
    skip: ({ user }) => {
      if (!user) return true
      if (user.role === TEACHER) return true
      if (!selectedSchool(user)) return true // user has not selected a school
      if (!getSchoolId()) return true
      return false
    },
    options: ({ settings }) => ({
      variables: {
        adminOnly: settings.adminOnlyRequests,
        schoolId: getSchoolId(),
      },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { dashboard, error } }) => ({
      dashboard,
      error,
    }),
  }),
  graphql(schoolQuery, {
    skip: ({ user }) => {
      if (!user) return true
      if (user.role === TEACHER) return true
      if (!selectedSchool(user)) return true // user has not selected a school
      if (!getSchoolId()) return true
      return false
    },
    options: () => ({
      variables: { schoolId: getSchoolId() },
      fetchPolicy: "network-only",
    }),
    props: ({ data: { school, error } }) => ({
      school,
      schoolError: error,
    }),
  })
)(withApollo(NavComponent))
